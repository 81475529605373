import axiosClient from "./axiosClient";

const checkInOutApi = {
    getList(token,params) {
      const url = "/cham-cong";
      return axiosClient.get(url,{params}, {
          headers: { Authorization: `Bearer ${token}` },
        });
    },
    // lấy ngày checkin-out
    check_date(token,params) {
      const url = "/cham-cong/check-date"+params;
      return axiosClient.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
    },
        //chấm công khi đã đăng nhập
  getWeek(token) {
    const url = "/cham-cong/week";
    return axiosClient.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
  },
  // tháng
  getMonth(token,params) {
    const url = "/cham-cong/month";
    return axiosClient.get(url, params,{
        headers: { Authorization: `Bearer ${token}` },
      });
  },
  // đăng ký vào
  checkIn(params, headers) {
    const url = "/cham-cong/checkin";
    return axiosClient.post(url, params, headers)
  },
  // đăng ký ra
  checkOut(params, headers) {
    const url = "/cham-cong/checkout";
    return axiosClient.post(url, params, headers)
  },
  //check 
  check(token) {
    const url = "/cham-cong/check";
    return axiosClient.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
  },
};

export default checkInOutApi;
