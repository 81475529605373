import React, { useState, useEffect } from "react";
import { getTimes, getTimestamp } from "../../utils/general";
import PopupDate from "../popupDate";

export default function CheckInOutHome({data}){
  const [userDate,setUserDate]=useState({
    date:null,
    total:null
  })
  const [popup,setPopup] = useState(false)
  function popupDate(date,total){
    setPopup(true)
    setUserDate({
      date:date,
      total:total
    })
  }
  function closePopup(){
    setPopup(false)
  }
  return (
   <>
     <hr className="mt-4 mb-3 border-y"/>
        <div className="pr-3 pl-3 mt-3 flex justify-between">
          <div className="flex">
              <p className="text-sm sm:text-base   font-bold ml-3 uppercase">Chấm công</p>
          </div>
          <a href="/lich-su-cham-cong" className="text-sm font-normal show-all flex gap-1 items-center">
            <span>
            xem thêm
            </span>
          </a>
          </div>
          <hr className="mt-3 mb-3 border-y lg:border-none"/>
        <div>
        <div className="px-4 overflow-x-auto">
                 <table className="w-full">
                   <tbody className="">
                     <tr className="h-16 border-b border-gray-200 last:border-none">
                       <td className=" min-w-[100px] lg:min-w-[100px]"><span className="font-medium text-[14px]">Ngày</span></td>
                       <td className=" min-w-[100px] lg:min-w-[700px] px-4"><span className="font-medium text-[14px]">Dữ liệu</span></td>
                     </tr>
                     {Object.keys(data.data).map(key => {
                      const items = data.data[key];
                      const totalHours = items.reduce((acc, item) => acc + (item.hours > 0 ? item.hours : 0), 0);
                      
                      return (
                        <tr key={'hp' + key} className="h-16 border-b border-gray-200 last:border-none">
                          <td className="min-w-[50px] lg:min-w-full px-4">
                            <span className="text-[14px]">{key}</span>
                          </td>
                          {items.length > 0 ? (
                            <td onClick={()=>popupDate(key,totalHours)} className="cursor-pointer min-w-[200px] lg:min-w-full px-4">
                              {
                               items.map(value=>(
                                <>
                                <div className="flex place-content-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#28B8DA" class="w-6 h-6">
                                <path stroke-linecap="#28B8DA" stroke-linejoin="#28B8DA" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                              </svg>
                              {getTimes('number',value.check_in_time)}
                            </div>
                            {
                                value.check_out_time && 
                                <div className="flex place-content-center">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FF0000" class="w-6 h-6">
                                    <path stroke-linecap="#FF0000" stroke-linejoin="#FF0000" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15m3 0 3-3m0 0-3-3m3 3H9" />
                                  </svg>
                                  {getTimes('number',value.check_out_time)}
                            </div>
                            }
                                </>
                            ))
                              }
                            </td>
                          ) : (
                            <td colSpan="2" className="text-center">Không có dữ liệu</td>
                          )}
                        </tr>
                      );
                    })}
                   </tbody>
                 </table>
               </div>
    </div>
    {
            popup == true ? 
              <PopupDate closePopup={closePopup} data={userDate}/>
              : ''
           }
   </>
  );
};