import axiosClient from "./axiosClient";

const authApi = {
    //đăng nhập
    login(params) {
        const url = "/auth/login";
        return axiosClient.post(url, params);
      },
      // lấy ca làm việc của người dùng
      shift(token) {
        const url = "/auth/user/shift";
        return axiosClient.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
      },
      // lấy ra những nhân sự cùng phòng ban
      userDepartment(token) {
        const url = "/auth/user/department";
        return axiosClient.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
      },
      // lấy thông tin đăng nhập
      user(token) {
        const url = "/auth/user";
        return axiosClient.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
      },
      updateUserInfo: (token, params) => {
        const url = "/auth/user/update";
        return axiosClient.post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
      },
      // thay đổi mật khẩu
      changePassword(params, headers) {
        const url = "/auth/password/change";
        return axiosClient.post(url, params, headers)
      },
};

export default authApi;
