import { useEffect, useState } from "react";
import checkInOutApi from "../../api/checkInOutApi";
import constants from "../../constants";
import { getTimestamp } from "../../utils/general";

export default function HistoryCheckIn({ data }) {
  const [listCheckIn,setListCheckIn] = useState({
    isShowButtonLoadMore: true,
    page: 1,
    data: [],
    total: 0,
  })
  useEffect(()=>{
    async function fetchCheckInOut() {
    const token = localStorage.getItem(constants.access_token)
    const params = {};
    checkInOutApi.getList(token,params).then((res)=>{
      if(res && res.status === 200){
          setListCheckIn({
            isShowButtonLoadMore:
            res.data.last_page <= res.data.current_page ? false : true,
            data:res.data.data,
            page:res.data.current_page,
            total:res.data.total
          })
      }
    })
  }
    fetchCheckInOut();
  },[])
  function load_more() {
    async function loadMoreCheckInOut() {
      const token = localStorage.getItem(constants.access_token)
      const params = {
        limit:20,
        page:listCheckIn.page + 1
      };
      checkInOutApi.getList(token,params).then((res)=>{
        if(res && res.status === 200){
            setListCheckIn({
              isShowButtonLoadMore:
              res.data.last_page <= res.data.current_page ? false : true,
              data:[...listCheckIn.data, ...res.data.data],
              page:res.data.current_page,
              total:res.data.total
            })
        }
      })
      }
      loadMoreCheckInOut()
  }
  return (
    <div id="main" className="overflow-hidden bg-white pb-20 xs:pb-0">
      <div className="sm:container lg:mb-[100px] mb-0">
        <div className="w-full max-w-4xl mx-auto mb-8">
          <div className="w-full bg-white px-4 mb-3">
          </div>

          <form id="" className="">
             <div className="w-full bg-white mb-3 overflow-hidden">
               <div className="flex items-center justify-between mb-3 py-4 border-b border-gray-200 pb-4 ">
                 <label className="text-[14px] px-4">Lịch sử chấm công</label>
               </div>
               <div className="px-4 overflow-x-auto">
                 <table className="">
                   <tbody className="">
                     <tr className="h-16 border-b border-gray-200 last:border-none">
                       <td className="px-4 min-w-[100px]"><span className="font-medium text-[14px]">STT</span></td>
                       <td className="px-4 min-w-[150px]"><span className="font-medium text-[14px]">Ảnh ra / vào </span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-[14px]">Ngày</span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-[14px]">Vào / ra</span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-base">Ip</span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-[14px]">Vị trí</span></td>
                    
                       {/* <td className="w-[20%] text-right"><span className="font-medium text-base">Chi tiết</span></td> */}
                     </tr>
                      {
                        Object.keys(listCheckIn).length > 0 ?
                        <>
                          {
                            listCheckIn.data.map((value, index) =>(
                              <tr key={'hp'+index} className="h-16 border-b border-gray-200 last:border-none">
                                <td className="min-w-[50px] px-4"><span className="text-[14px]">{index+1}</span></td>
                                <td className="min-w-[50px] px-4 flex">
                                {value?.image_in != null ? (
                                <img
                                  src={constants.IMAGE_HOST+value.image_in}
                                  className="pr-2"
                                  height={70}
                                  width={70}
                                  alt='avatar'
                                />
                              ) : ''}
                               {value?.image_out != null ? (
                                <img
                                  src={constants.IMAGE_HOST+value.image_out}
                                  className=""
                                  height={70}
                                  width={70}
                                  alt='avatar'
                                />
                              ) : ''}
                                </td>
                                <td className="min-w-[150px] px-4"><span className="text-[14px]">{value.date}</span></td>
                                <td className="min-w-[200px] px-4"><span className="text-[14px]">{getTimestamp('number',value.check_in_time) + '  ' + getTimestamp('number',value.check_out_time)}</span></td>
                                <td className="min-w-[150px] px-4"><span className="text-[14px]">{value.ip}</span></td>
                                <td className="min-w-[150px] px-4"><span className="text-[14px]">{value.location_in}</span></td>
                              </tr>
                                ))
                          }
                        </>
                        
                        :
                        <div className="flex items-center justify-between mb-3 py-4 border-b border-gray-200 pb-4 ">
                          <h1 className="text-[14px] px-4 text-center">Bạn không có lịch sử chấm công</h1>
                        </div>
                      }
                   </tbody>
                 </table>
               </div>
             </div>
           </form> 
           {
              listCheckIn.isShowButtonLoadMore === true ? (
                <div className="flex items-center justify-center lg:pt-2 lg:pb-5 bg-white rounded sm:rounded-none overflow-hidden">
                  <button
                    title="Xem thêm"
                    className="flex items-center justify-center cursor-pointer lg:bg-[#EDF2FA] rounded py-2.5 px-5"
                  >
                    <span
                      onClick={load_more}
                      className="text-sm text-color4 sm:text-[#2B6FD5]"
                    >
                      Xem thêm {listCheckIn.total - (listCheckIn.page * 20)}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-caret-down-fill text-color4 ml-2 sm:text-color3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                    </svg>
                  </button>
                </div>
              ) : (
                ""
              )
            }
        </div>
      </div>
    </div>
  );
}

