import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/font.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'; 
import { store } from './store/store';
ReactDOM.render(
  <Provider store={store}>
    <link href="https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700&display=swap" rel="stylesheet"></link>
    <React.StrictMode>
      <App />
    </React.StrictMode>
   </Provider>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
