import React from "react";

// hàm đổi timestamp thành ngày giờ cụ thể
export function getTimestamp(type, timestamp) {
    if (type == "number") {
      var date = new Date(timestamp * 1000);
    } else {
      var date = new Date(timestamp);
    }
    // Lấy các thành phần ngày và giờ cụ thể từ đối tượng Date
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Tháng tính từ 0, cần +1 và định dạng thành chuỗi 2 chữ số
    const day = ("0" + date.getDate()).slice(-2); // Định dạng thành chuỗi 2 chữ số
    const hours = ("0" + date.getHours()).slice(-2); // Định dạng thành chuỗi 2 chữ số
    const minutes = ("0" + date.getMinutes()).slice(-2); // Định dạng thành chuỗi 2 chữ số
    const seconds = ("0" + date.getSeconds()).slice(-2); // Định dạng thành chuỗi 2 chữ số
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }
  export function getTimes(type, timestamp) {
    if (type === "number") {
      var date = new Date(timestamp * 1000);
    } else {
      var date = new Date(timestamp);
    }
    // Lấy các thành phần ngày và giờ cụ thể từ đối tượng Date
    const hours = ("0" + date.getHours()).slice(-2); // Định dạng thành chuỗi 2 chữ số
    const minutes = ("0" + date.getMinutes()).slice(-2); // Định dạng thành chuỗi 2 chữ số
    const seconds = ("0" + date.getSeconds()).slice(-2); // Định dạng thành chuỗi 2 chữ số
    const formattedDateTime = `${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }