
import { useRef, useState, useEffect } from "react";
import constants from "../../constants";
import companyApi from "../../api/companyApi";
export default function Company() {
  const [company,setCompany] = useState([])
  useEffect(()=>{
    const token = localStorage.getItem(constants.access_token);
    if(token){
      companyApi.company(token).then((res) => {
        if (res && res.status == 200) {
          setCompany(res.data);
        }
      }); 
    }else{
      window.location.href = "/";
    }
  },[])
  return (
    <div>
      <div className="bg-white text-sm xl:mt-[0]">
        <h2 className="pl-6 h-20 font-semibold text-2xl leading-[80px] mb-4 ">
          Thông tin công ty
        </h2>
        <div className="xl:pl-12 xl:pr-[50px] xl:flex block gap-6 pb-12">
          <div className="p-6 w-full bg-white rounded-lg xl:border">
            <h3 className="text-lg font-semibold">Công ty</h3>
            <div className="flex items-center justify-between mt-4">
              <p>Tên công ty</p>
              <p>{company?.company?.name}</p>
            </div>

            <div className="flex items-center justify-between mt-4">
              <p>Địa chỉ</p>
              <p>{company?.company?.address}</p>
            </div>
            <div className="flex items-center justify-between mt-4">
              <p>Số điện thoại</p>
              <p>{company?.company?.phone}</p>
            </div>

            <div className="flex items-center justify-between mt-4">
              <p>Email</p>
              <p>{company?.company?.email}</p>
            </div>


            {/* <div className="flex text-xs text-[#999] w-[68%]">
              Số tiền khuyến mãi sau khi nạp tiền. Có thể thanh toán bất kì loại
              tin nào.
            </div> */}

            <div className="flex items-center justify-between mt-4">
              <p>Mã</p>
              <p>{company?.company?.tax_code}</p>
            </div>
            <div className="w-full mt-4">
                <p>Mô tả: <span>{company?.company?.description}</span></p>
            </div>

            {/* <div className="flex items-center justify-between mt-4">
              <p className="font-semibold">Tổng cộng</p>
              <p className="color1 text-lg">40.000</p>
            </div> */}
          </div>
        </div>

      </div>
    </div>
  );
}
