import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  user: {},
};

export const authenticate = createSlice({
  name: "authenticate",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLogin = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isLogin = false;
      state.user = {};
    },
  },
});

export const { login, logout } = authenticate.actions;

export default authenticate.reducer;
