import React, { useEffect, useRef, useState } from "react";
import constants from "../../constants";
import checkInOutApi from "../../api/checkInOutApi";
import { successToast, warnToast } from "../../utils/toast";
import { useSelector } from "react-redux";
import Webcam from "react-webcam";
import LazyLoad from 'react-lazyload';
const videoConstraints = {
  width: 540,
  facingMode: "user"
};
export default function CheckInOutPopup(props) {
  const userInfo = useSelector((state) => state.authReducer);
  const [file, setFile] = useState(null);
  const [popupImage, setPopupImage] = useState(false);
  const [latLongIn, setLatLongIn] = useState({
    latIn: null,
    LongIn: null,
  });
  const [webcamLoaded, setWebcamLoaded] = useState(false);
  const [time, setTime] = useState(new Date());
  const [ipV4, setIpV4] = useState(null);
  const [check, setCheck] = useState({
    type: null,
    message: "",
  });
  const webcamRef = useRef(null);
  const onUserMedia = () => {
    setWebcamLoaded(true);
  };
  const capturePhoto = React.useCallback(async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
          fetch(imageSrc)
              .then(res => res.blob()) // Convert base64 URL to blob
              .then(blob => {
                  const capturedFile = new File([blob], Date.now(), { type: 'image/png' });
                  setFile(capturedFile);
                  setPopupImage(false);
              })
              .catch(error => alert('Không thể truy xuất camera của bạn', error));
      } else {
          alert('Không thể truy xuất camera của bạn.');
      }
  } catch (error) {
      alert('Không thể truy xuất camera của bạn:', error);
  }
  
  }, [webcamRef]);
  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      setIpV4(data.ip)
    })
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatLongIn({
            latIn:position.coords.latitude,
            LongIn:position.coords.longitude
          });

        },
        (error) => {
          alert('Không thể truy xuất vị trí của bạn');
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
    const token = localStorage.getItem(constants.access_token);
    checkInOutApi.check(token).then((response) => {
      if (response.result == true) {
        setCheck({
          type: response.type,
          message: response.message,
        });
      }
    });
  }, []);
  // lấy địa chỉ hiện tại 
  
  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(intervalID);
  }, []);
  // lấy địa chỉ cty đến địa chỉ hiện tại
  const secondsDeg = (time.getSeconds() / 60) * 360;
  const minutesDeg = ((time.getMinutes() + time.getSeconds() / 60) / 60) * 360;
  const hours = time.getHours() % 12 || 12; // Chuyển đổi giờ sang hệ 12 giờ
  const hoursDeg = ((hours + time.getMinutes() / 60) / 12) * 360;
  function checkInOut(e) {
    e.preventDefault();
    const token = localStorage.getItem(constants.access_token);
    if (check.type === 1) {
      const formData = new FormData();
      if (file != null) {
        formData.append("image_in", file);
      }
      formData.append("ip", ipV4);
      formData.append("latitude_in", latLongIn.latIn);
      formData.append("longitude_in", latLongIn.LongIn);
      checkInOutApi
        .checkIn(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.result === true) {
            props.closePopup();
            successToast(res.message, 1500);
            setTimeout(() => {
              window.location.href = "/";
            }, 2000);
          } else {
            props.closePopup();
            warnToast(res.message, 1500);
          }
        });
    } else if (check.type === 2) {
      const formData = new FormData();
      formData.append("image_out", file);
      formData.append("ip", ipV4);
      formData.append("latitude_out", latLongIn.latIn);
      formData.append("longitude_out", latLongIn.LongIn);
      checkInOutApi
        .checkOut(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.result === true) {
            props.closePopup();
            successToast(res.message, 1500);
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
          } else {
            props.closePopup();
            warnToast(res.message, 1500);
          }
        });
    }
  }
  function checkImage() {
    setPopupImage(true);
  }
  function closeImage() {
    setWebcamLoaded(false);
    setPopupImage(false);
    setFile(null);
  }
  return (
    <>
      <div
        before=""
        className="w-full h-full fixed top-0 left-0 before:content-[attr(before)] before:bg-black before:top-0 before:left-0 before:absolute before:w-full before:h-full before:opacity-70 z-[99999]"
      >
        <div className="w-11/12 max-w-3xl bg-gray-200 py-7 px-3 sm:p-4 md:p-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div
            onClick={() => props.closePopup()}
            className="close absolute top-0 right-0 rounded-full bg-gray-400 overflow-hidden text-white p-1 translate-x-1/2 -translate-y-1/2 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
          {file && (
                    <div>
                      <img src={URL.createObjectURL(file)}
                      className="mt-3 mx-auto max-w-[400px] max-h-[250px] rounded"
                      alt="Screenshot" />
                    </div>
                  )}
       
              <div>
            {/* <div className=" flex justify-center items-center"> 
                            <video ref={videoRef} width="320" height="300" autoPlay></video>
                        <button onClick={captureImage}>Chụp ảnh</button>
                        <canvas ref={canvasRef} width="320" height="240" style={{ display: 'none' }}></canvas>
                        {image && <img src={image} alt="Captured" />}
                        </div> */}
                        {
                          !file && (
                            <div className="flex justify-center items-center">
                            <div className="relative w-64 h-64 border-4 border-gray-700 rounded-full">
                              {/* Kim giờ */}
                              <div
                                className="absolute w-2 h-24 bg-gray-900 top-[30px] transform -translate-x-1/2 -translate-y-full left-1/2 origin-bottom"
                                style={{ transform: `rotate(${hoursDeg}deg)` }}
                              />
                              {/* Kim phút */}
                              <div
                                className="absolute w-2 h-24 bg-gray-900 top-[30px] transform -translate-x-1/2 -translate-y-full left-1/2 origin-bottom"
                                style={{ transform: `rotate(${minutesDeg}deg)` }}
                              />
                              {/* Kim giây */}
                              <div
                                className="absolute w-1 h-24 bg-red-500 top-[27px] transform -translate-x-1/2 -translate-y-full left-1/2 origin-bottom"
                                style={{ transform: `rotate(${secondsDeg}deg)` }}
                              />
                              {/* Hiển thị các số từ 1 đến 12 */}
                              <ul className="p-0">
                                <li className="absolute right-[22%] top-[6.5%]">
                                  <span>1</span>
                                </li>
                                <li className="absolute right-[8%] top-[25%]">
                                  <span>2</span>
                                </li>
                                <li className="absolute right-[2%] top-[50%]">
                                  <span>3</span>
                                </li>
                                <li className="absolute right-[9%] top-[69%]">
                                  <span>4</span>
                                </li>
                                <li className="absolute right-[23%] top-[84%]">
                                  <span>5</span>
                                </li>
                                <li className="absolute right-[47%] top-[90%]">
                                  <span>6</span>
                                </li>
                                <li className="absolute left-[22%] top-[84%]">
                                  <span>7</span>
                                </li>
                                <li className="absolute left-[8%] top-[69%]">
                                  <span>8</span>
                                </li>
                                <li className="absolute left-[2%] top-[50%]">
                                  <span>9</span>
                                </li>
                                <li className="absolute left-[6%] top-[25%]">
                                  <span>10</span>
                                </li>
                                <li className="absolute left-[22%] top-[6.5%]">
                                  <span>11</span>
                                </li>
                                <li className="absolute right-[47%] top-[1%]">
                                  <span>12</span>
                                </li>
                              </ul>
                              {/* Mặt đồng hồ */}
                              <div className="absolute w-8 h-8 bg-gray-700 rounded-full transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                            </div>
                          </div>
                          )
                        }
            <div className="mt-3 gap-3 items-center justify-center">
              {check && (
                <button
                  onClick={() => checkImage()}
                  className="px-3 py-[6px] font-semibold border rounded color1 border-[#f73a2d] bg-[#f73a2d]"
                >
                  <span className="text-white">Chụp ảnh</span>
                </button>
              )}
            </div>
            <div className="mt-3 gap-3 items-center justify-center">
            {
              check && (
                  <button
                    onClick={(e) => checkInOut(e)}
                    className="px-3 py-[6px] font-semibold border rounded color1 border-[#3851C3] bg-[#3851C3]"
                  >
                    <span className="text-white">{check.message ? check.message : "Đăng ký vào" }</span>
                  </button>
                )
            }
            </div>
          </div>
        </div>
      </div>
      {popupImage === true ? (
        <div
          before=""
          className="w-full h-full fixed top-0 left-0 before:content-[attr(before)] before:bg-black before:top-0 before:left-0 before:absolute before:w-full before:h-full before:opacity-70 z-[99999]"
        >
          <div className="w-full max-h-[90%] p-4 overflow-y-auto rounded max-w-3xl  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div
              onClick={() => closeImage()}
              className="close absolute top-0 right-0 rounded-full bg-gray-400 overflow-hidden text-white p-1 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
            <div className="w-full h-full bg-gray-200 py-7 px-3 sm:p-4 md:p-5">
              <div className="">
                <h1 className="mb-2">Chụp ảnh để {check.message}</h1>
                <div className="items-center justify-center">
          
                {!webcamLoaded && (
                  <LazyLoad height={250}>
                    <div>Loading...</div> {/* Thay thế bằng một thông báo hoặc spinner loading */}
                  </LazyLoad>
                )}
                    <Webcam
                    ref={webcamRef}
                    audio={false}
                    className="mx-auto max-w-[400px] max-h-[250px] rounded"
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    onUserMedia={onUserMedia}
                  />
                   <div className="mt-3 gap-3 items-center justify-center">
                  <button
                    className="px-3 py-[6px] font-semibold border rounded color1 border-[#3851C3] bg-[#3851C3]"
                    onClick={capturePhoto}
                  >
                    <span className="text-white">Chụp ảnh</span>
                  </button>
                
                </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
