import { createSlice } from "@reduxjs/toolkit";

export const configSlice = createSlice({
  name: "config",
  initialState: {
    config: {},
  },
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
  },
});

export const {setConfig} = configSlice.actions;
// export const selectUser = (state) => state.config.config;

export default configSlice.reducer;
