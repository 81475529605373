import { useEffect, useState } from "react";
import constants from "../../constants";
import { DatePicker } from "antd";
import authApi from "../../api/authApi";
import moment from "moment";
import timeOffApi from "../../api/timeOffApi";
import { successToast, warnToast } from "../../utils/toast";

export default function NghiPhep() {
  const [title, setTitle] = useState("");
  const [timeInputs, setTimeInputs] = useState([]);
  const [selectedFiles, setselectedFiles] = useState([])
  const [listImage, setLisImage] = useState('')
  const [shift,setShift] = useState([])
  const [userDepartments,setUserDepartments] = useState([])
  useEffect(()=>{
    const token = localStorage.getItem(constants.access_token);
    // lấy ra ca làm việc của người dùng
    // authApi.shift(token).then((response) => {
    //   if (response.result == true && response.data) {
    //     setShift(response.data);
    //   }
    // });
    // lấy ra những nhân sự cùng phòng ban
    authApi.userDepartment(token).then((response)=>{
      if (response.result == true && response.data) {
        setUserDepartments(response.data);
      }
    })
  },[])
  const addTimeInput = () => {
    setTimeInputs([...timeInputs, { date: null}]);
  };
   const deleteTimeInput = (index) => {
    const newTimeInputs = [...timeInputs];
    newTimeInputs.splice(index, 1);
    setTimeInputs(newTimeInputs);
  }; 
  const onChangeDate = (date, index) => {
    const newTimeInputs = [...timeInputs];
    newTimeInputs[index].date = date;
    setTimeInputs(newTimeInputs);
  };
  console.log(timeInputs);
  // const handleCheckboxChange = (value, timeInputIndex, shiftIndex) => {
  //   const updatedTimeInputs = [...timeInputs];
  //   updatedTimeInputs[timeInputIndex].shifts[shiftIndex] = value;
  //   setTimeInputs(updatedTimeInputs);
  // };
  function submitTimeOff(e) {
    e.preventDefault();
    console.log(Object.keys(timeInputs).length)
    const token = localStorage.getItem(constants.access_token);
    if(Object.keys(timeInputs).length == 0){
        warnToast('Bạn chưa chọn ngày nghỉ', 1500);
        return false;
    }
    if(Object.keys(timeInputs).length > 0){
      const allFilled = timeInputs.every(input => input.date !== null);
      if (!allFilled) {
        warnToast('Bạn chưa nhập ngày nghỉ', 1500);
        return false;
      }
    }
    if(e.target.title.value == '' || e.target.title.value == undefined){
      warnToast('Bạn chưa nhập tiêu đề nghỉ', 1500);
      return false;
    }
    if(e.target.type.value == '' || e.target.type.value == undefined){
      warnToast('Bạn chưa chọn loại nghỉ phép', 1500);
      return false;
    }
    if(e.target.follower_id.value == '' || e.target.follower_id.value == undefined){
      warnToast('Bạn chưa chọn người theo dõi', 1500);
      return false;
    }
    const formData = new FormData();
    for (let i = 0; i < listImage.length; i++) {
        formData.append('images[]', listImage[i]);
    }
    formData.append('title', e.target.title.value);
    formData.append('type', e.target.type.value);
    formData.append('type_timeoff', e.target.type_timeoff.value);
    formData.append('reason', e.target.reason.value);
    formData.append('follower_id', e.target.follower_id.value);
    formData.append('handover_recipients', e.target.handover_recipients.value);
    if(Object.keys(timeInputs).length > 0){
      formData.append('time', JSON.stringify(timeInputs));
    }
    timeOffApi
    .postTimeOff(formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.status == 200) {
        successToast("Tạo nghỉ phép thành công", 1500);
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      } else {
        warnToast(response.message, 1500);
      }
    });
  }
  const handleImage = (e) => {
      setLisImage(e.target.files)
      setselectedFiles([]);
      if (e.target.files) {
          const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file))
          const avatars = selectedFiles.concat(filesArray)
          setselectedFiles((prevImages) => prevImages.concat(avatars))
          Array.from(e.target.files).map((file) => URL.revokeObjectURL(file))
      }
  }
  const renderImage = (source) => {
      return source.map((image, index) => {
          return (
              <div className='flex' key={'im' + index}>
                  <img className='rounded-sm' width="150px" height="150px" src={image} />
                  <svg onClick={(e) => (setselectedFiles(selectedFiles.filter((value, ind) => ind !== index)))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
              </div>
          )
      })
  }
  return (
    <div id="main" className="overflow-hidden bg-gray-100 pb-20">
      <div className="">
        <div className="w-full max-w-4xl mx-auto mb-8">
          <div className="w-full bg-white mb-3">
            <div className="flex items-center p-4 xs:py-6 xs:px-8 border-b border-gray-300">
              {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className="mr-2 co">
                        <path d="M12 14.998C14.2091 14.998 16 13.2072 16 10.998C16 8.78891 14.2091 6.99805 12 6.99805C9.79086 6.99805 8 8.78891 8 10.998C8 13.2072 9.79086 14.998 12 14.998ZM12 8.99805C13.1046 8.99805 14 9.89348 14 10.998C14 12.1026 13.1046 12.998 12 12.998C10.8954 12.998 10 12.1026 10 10.998C10 9.89348 10.8954 8.99805 12 8.99805Z" />
                        <path d="M4 22.998C2.89543 22.998 2 22.1026 2 20.998V4.99805C2 3.89348 2.89543 2.99805 4 2.99805H20C21.1046 2.99805 22 3.89348 22 4.99805V20.998C22 22.1026 21.1046 22.998 20 22.998H4ZM4 4.99805V20.998H7C7 18.2366 9.23858 15.998 12 15.998C14.7614 15.998 17 18.2366 17 20.998H20V4.99805H4ZM15 20.998C15 19.3412 13.6569 17.998 12 17.998C10.3431 17.998 9 19.3412 9 20.998H15Z" />
                    </svg> */}
              <h2 className="text-base xs:text-lg text-cltext font-medium">
                Tạo nghỉ phép
              </h2>
            </div>
            <form onSubmit={(e) => submitTimeOff(e)}  encType="multipart/form-data">
              <div className="p-2 xs:py-6 xs:px-8">
                <div className="mb-2">
                <input
                    type="text"
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                    className="rounded p-3 mb-3 focus:outline-none border border-gray-200 h-auto placeholder-bdtext text-cltext text-sm w-full"
                    placeholder="Tiêu đề"
                    id="typemsg"
                    value={title}
                  />
                   <div className="mb-3 p-3 border border-gray-200 bg-white rounded overflow-hidden">
                        <select name="type" id="type" className="bg-transparent cursor-pointer text-gray-500 focus:text-black text-sm w-full focus:outline-none">
                            <option value="0">Loại</option>
                            <option value="1">Nghỉ phép</option>
                            <option value="2">Đi làm trễ</option>
                            <option value="3">Đi về sớm</option>
                            <option value="4">Đi ra ngoài</option>
                            <option value="5">Đi công tác</option>
                        </select>
                    </div>
                    <div className="mb-3 p-3 border border-gray-200 bg-white rounded overflow-hidden">
                        <select name="type_timeoff" id="type_timeoff" className="bg-transparent cursor-pointer text-gray-500 focus:text-black text-sm w-full focus:outline-none">
                            <option value="0">Loại nghỉ phép</option>
                            <option value="1">Nghỉ thường niên</option>
                            <option value="2">Làm việc riềng không lương</option>
                            <option value="3">Nghỉ ốm</option>
                            <option value="4">Nghỉ đẻ</option>
                        </select>
                    </div>
                    <div className="mb-3 p-3 border border-gray-200 bg-white rounded overflow-hidden">
                        <select name="follower_id" id="follower_id" className="bg-transparent cursor-pointer text-gray-500 focus:text-black text-sm w-full focus:outline-none">
                            <option value="">Chọn người theo dõi</option>
                            {userDepartments && 
                              userDepartments.map((value,ind)=>(
                                <option key={ind} value={value.id}>{value.name}</option>
                              ))
                            }
                
                        </select>
                    </div>
                    <div className="mb-3 p-3 border border-gray-200 bg-white rounded overflow-hidden">
                        <select name="handover_recipients" id="handover_recipients" className="bg-transparent cursor-pointer text-gray-500 focus:text-black text-sm w-full focus:outline-none">
                            <option value="">Bàn giao</option>
                            {userDepartments && 
                              userDepartments.map((value,ind)=>(
                                <option key={ind} value={value.id}>{value.name}</option>
                              ))
                            }
                        </select>
                    </div>
                    <div className="mb-2">
                    <label htmlFor="file" className="flex items-center gap-2 cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-camera-fill text-[#1C222B]" viewBox="0 0 16 16"><path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path><path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"></path></svg><span className="text-sm text-texth">Thêm hình ảnh</span>
                            </label>
                    <input type="file" multiple onChange={handleImage} id="file" name="image" className='hidden' />
                          <div className='grid gap-2 grid-cols-2'>{renderImage(selectedFiles)}</div>
                  </div>
                  <textarea name="reason"
                   placeholder="Lý do nghỉ"
                    className="rounded p-3 mb-3 focus:outline-none border border-gray-200 h-auto placeholder-bdtext text-cltext text-sm w-full">
                  </textarea>
                  
                </div>
                <div className="border-t-[3px] p-4 xs:p-6 border-gray-500">
                    <h2 className="text-base xs:text-lg text-cltext font-medium">
                    Chọn thời gian nghỉ
                    </h2>
                </div>
              {timeInputs.map((input, index) => (
                <div key={index} className="mb-3 border border-gray-400 bg-gray-400 rounded">
                <div className="mx-2 items-center gap-2 justify-between">
                  <div className="pt-2">
                    <label className="mb-1 text-sm text-texth font-medium">Ngày {index + 1}</label>
                    <DatePicker className="w-full" 
                      selected={input.date}
                      onChange={(date) => onChangeDate(date, index)}/>
                  </div>
              </div>
              <div className="px-2 flex gap-6 sm:gap-12 mb-7 mt-2 relative">
                <div className="flex items-center">
                  {/* <label className="text-sm cursor-pointer inline-block text-texth">
                      Thời gian
                  </label> */}
                  {/* <input
                      type="local-time"
                      name="title"
                      onChange={(e) => setTitle(e.target.value)}
                      className="rounded mb-3 focus:outline-none border border-gray-200 placeholder-bdtext text-cltext text-sm w-full"
                      placeholder="Thời gian"
                      id="typemsg"
                      value={title}
                    /> */}
                </div>
                {/* {shift && 
                  shift.map((value,ind)=>(
                    <div key={ind} className="flex items-center">
                  <input className="appearance-none h-4 w-4 relative border border-gray-400 bg-white checked:border-green-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer before:content-[attr(before)] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-2 before:h-2 before:rounded-full checked:before:bg-green-600" type="checkbox" name="flexRadioDefault"
                  id={`flexRadioDefault_${index}_${ind}`}
                  onChange={()=>handleCheckboxChange(value,index,ind)}/>
                  <label className="text-sm cursor-pointer inline-block text-texth" htmlFor={`flexRadioDefault_${index}_${ind}`} >
                      {value.shift_name + ' ' + value.start_time +'h - '+value.end_time+'h'}
                  </label>
                </div>
                  ))
                } */}
                  <button onClick={()=>deleteTimeInput(index)} className="absolute top-[-5px] right-0 mt-1 mr-2 w-3/12 overflow-hidden bg-[#cf3333] border rounded border-[#cf3333] text-white">
                  <span className="text-sm xs:text-base font-medium leading-[100%] z-[2]">
                      Xóa
                  </span>
                  </button>
              </div>
              </div>
              ))}
              <a onClick={addTimeInput} className="mb-4 p-3 xs:p-4 overflow-hidden bg-[#5bb2e4] border border-[#5bb2e4] text-white leading-[0] rounded flex items-center justify-center relative hover:bg-white transition-all hover:text-[#1C222B]">
                <span className="text-sm xs:text-base font-medium leading-[100%] z-[2]">
                Thêm ngày nghỉ phép
                </span>
              </a>
                <button className="w-full p-3 xs:p-4 overflow-hidden bg-[#17af26] border border-[#17af26] text-white leading-[0] rounded flex items-center justify-center relative hover:bg-white transition-all hover:text-[#1C222B]">
                  <span className="text-sm xs:text-base font-medium leading-[100%] z-[2]">
                    Tạo nghỉ phép
                  </span>
                </button>
                {/* <p className="text-center text-green-700 pt-3">{message}</p> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
