
import { useEffect, useState } from 'react';

export default function Footer({ data }) {
    // function checkLogin(){
    //     if (typeof window !== 'undefined' && localStorage.getItem('token')) {
    //         router.push({
    //             pathname: ROUTERS.trang_tai_khoan,
    //             query: {}
    //         });
    //     } else {
    //         dispatch(setLoadFrom(true))
    //     }  
    // }
    const Navigation = () => {
        const [activeLink, setActiveLink] = useState('');
      
        useEffect(() => {
          const pathName = window.location.pathname;
          setActiveLink(pathName);
        }, []);
        return (
            <ul className="grid gap-2 grid-cols-3 items-center nav-foot">
                  <li className={`${activeLink === '/' ? 'active' : ''}`}>
                    <a href={'/'} className={`group text-center`}>
                            <div className="relative">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="w- bi bi-house-door 6 h-6 lg:w-7 lg:h-7 mx-auto mb-2 text-white group-hover:text-white" viewBox="0 0 16 16">
                            <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4z"/>
                            </svg>                 
                            </div>
                            <p className="text-black group-hover:text-red text-[10px]">Trang chủ</p>
                    </a>
                </li>
                <li className={`${activeLink === '/bang-luong' ? 'active' : ''}`}>
                    <a href={'/bang-luong'} className={`group text-center`}>
                            <div className="relative">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-wallet mx-auto mb-2 6 h-6 lg:w-7 lg:h-7 text-white group-hover:text-white" viewBox="0 0 20 20">
                            <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1"/>
                            </svg>
                            </div>
                            <p className="text-black group-hover:text-red text-[10px]">Bảng chấm công</p>
                    </a>
                </li>
                {/* <li className={`${activeLink === '/cong-ty' ? 'active' : ''}`}>
                    <a href={'/cong-ty'} className={`group text-center`}>
                            <div className="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-building mx-auto mb-2 6 h-6 lg:w-7 lg:h-7 text-white group-hover:text-white" viewBox="0 0 16 16">
                            <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                            <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
                            </svg>
                            </div>
                            <p className="text-black group-hover:text-red text-[10px]">Công ty</p>
                    </a>
                </li> */}
                <li className={`${activeLink === '/tai-khoan' ? 'active' : ''}`}>
                        <a href='/tai-khoan' title="" className={`group text-center`}>
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 20 20" fill="black" className="mx-auto mb-2 6 h-6 lg:w-7 lg:h-7 text-white group-hover:text-white">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M10 2.00061C8.3971 2.00061 7.09766 3.30005 7.09766 4.90299C7.09766 6.50593 8.3971 7.80537 10 7.80537C11.603 7.80537 12.9024 6.50593 12.9024 4.90299C12.9024 3.30005 11.603 2.00061 10 2.00061ZM5.09766 4.90299C5.09766 2.19548 7.29253 0.000610352 10 0.000610352C12.7075 0.000610352 14.9024 2.19548 14.9024 4.90299C14.9024 7.6105 12.7075 9.80537 10 9.80537C7.29253 9.80537 5.09766 7.6105 5.09766 4.90299Z" fill="" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.51784 13.3905C4.47035 13.3905 2 15.8609 2 18.9083V18.9995C2 19.5518 1.55228 19.9995 1 19.9995C0.447715 19.9995 0 19.5518 0 18.9995V18.9083C0 14.7563 3.36578 11.3905 7.51784 11.3905H12.4822C16.6342 11.3905 20 14.7563 20 18.9083V18.9995C20 19.5518 19.5523 19.9995 19 19.9995C18.4477 19.9995 18 19.5518 18 18.9995V18.9083C18 15.8609 15.5296 13.3905 12.4822 13.3905H7.51784Z" fill="" />
                                </svg>
                            </div>
                            <p className="text-black group-hover:text-red text-[10px]">Tài khoản</p>
                        </a>
                </li>
            </ul>
        )
    };
    return (
        <>
            {/* <ToastContainer
                className={`${styles.ToastCt}`}
            /> */}
            <footer className="">
                {/* <div className="">
                    <div className="pt-4 pb-2 lg:pt-5 lg:pb-5 flex justify-center items-center">
                        <div className="text-texth text-xs">
                            <span>Copyright © 2022 T-Mart - </span><a href="" title="">Design by Nanoweb</a>
                        </div>
                    </div>
                </div> */}
                <div className="fixed bottom-0 left-0 z-50 justify-between w-full h-16 lg:h-24 place-content-center items-center bg-white rounded-t-[25px] before:content-[attr(before)] before:-z-10 before:w-5 before:h-5 before:bg-color6 before:absolute before:rotate-[-135deg] before:-top-2.5 before:left-[calc(50%-10px)] shadow-[0px_-3px_17px_8px] shadow-[#495abf1c]">
                    <div className="w-full px-2 mx-auto ">
                    <Navigation />
                    </div>
                </div>
            </footer>
        </>
    );

}

