import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import logOutHandler from "../../utils/logOutHandler";
import { logout } from "../../store/slices/authSlice";
import constants from "../../constants";
import { successToast } from "../../utils/toast";

const Account = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authReducer);
  useEffect(()=>{
    const token = localStorage.getItem(constants.access_token);
    if(!token){
        window.location.href = "/login";
    }
  },[])
  const logOutHandlerFn = () => {
    logOutHandler();
    dispatch(logout());
    successToast('Đăng xuất thành công!',1500)
    window.location.href = "/login";
  };
  return (
    <>
      <div>
        <div className=" px-3 lg:px-0 h-[90px] bg-header-gradient w-full overflow-hidden ">
        </div>

        <div className=" pb-[10px]  max-w-[875px] w-full  mx-auto shadow-[#495abf1c] mt-[-100px] bg-white text-mine-shaft-700">
          <div className="border-b-4 border-gray-100 px-8 pt-4 pb-2">
            <a
              type="button"
              href="/thong-tin-ca-nhan"
              className="text-sm lg:text-base flex gap-5 items-center py-3"
            >
              <span>Thông tin cá nhân</span>
            </a>
            <a
              type="button"
              href="/cong-ty"
              className="text-sm lg:text-base flex gap-5 items-center py-3"
            >
              <span>Công ty</span>
            </a>
          </div>
          <div className="px-8 py-2 border-b-4 border-gray-100">
            <a
              href="/lich-su-nghi-phep"
              className="text-sm lg:text-base flex gap-5 px-1 items-center py-3 "
            >  <span>Lịch sử nghỉ phép</span>
            </a>
            <a
              href="/lich-su-cham-cong"
              className="text-sm lg:text-base flex gap-5 px-1 items-center py-3 "
            >
              <span>Lịch sử chấm công</span>
            </a>
          </div>
          <div className="px-8 py-2">
            <a
              type="button"
              href="/doi-mat-khau"
              className="text-sm lg:text-base flex gap-5 px-1 items-center py-3"
            >
              <span>Đổi mật khẩu</span>
            </a>
            {userInfo.isLogin && (
              <button
                type="button"
                onClick={logOutHandlerFn}
                className="text-sm lg:text-base flex gap-5 px-1 items-center py-3"
              >
                <span>Đăng xuất</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
