import { useEffect, useState } from "react";
import constants from "../../constants";
import { getTimestamp } from "../../utils/general";
import timeOffApi from "../../api/timeOffApi";

export default function HistoryTimeOff({ data }) {
  const [listTimeOff,setListTimeOff] = useState({
    data:[],
    page:1,
    total:null
  })
  useEffect(()=>{
    const token = localStorage.getItem(constants.access_token)
    timeOffApi.getList(token).then((res)=>{
      if(res && res.status == 200){
        setListTimeOff({
            data:res.data.data,
            page:res.data.current_page,
            total:res.data.total
        })
      }
    })
  },[])
  return (
    <div id="main" className="overflow-hidden bg-white pb-20 xs:pb-0">
      <div className="sm:container">
        <div className="w-full max-w-4xl mx-auto mb-8">
          <div className="w-full bg-white px-4 mb-3">
          </div>

          <form id="" className="">
             <div className="w-full bg-white mb-3 overflow-hidden">
               <div className="flex items-center justify-between mb-3 py-4 border-b border-gray-200 pb-4 ">
                 <label className="text-[14px] px-4">Lịch sử nghỉ phép</label>
               </div>
               <div className="px-4 overflow-x-auto">
                 <table className="">
                   <tbody className="">
                     <tr className="h-16 border-b border-gray-200 last:border-none">
                       <td className="px-4 min-w-[100px]"><span className="font-medium text-[14px]">STT</span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-[14px]">Tiêu đề</span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-[14px]">Người theo dõi</span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-[14px]">Bàn giao cho</span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-base">Thời gian tạo</span></td>
                       <td className=" min-w-[100px] px-4"><span className="font-medium text-[14px]">Trạng thái</span></td>
                    
                       {/* <td className="w-[20%] text-right"><span className="font-medium text-base">Chi tiết</span></td> */}
                     </tr>
                      {
                        Object.keys(listTimeOff).length > 0 ?
                        listTimeOff.data.map((value, index) =>(
                      <tr key={'hp'+index} className="h-16 border-b border-gray-200 last:border-none">
                        <td className="min-w-[50px] px-4"><span className="text-[14px]">{index+1}</span></td>
                        <td className="min-w-[50px] px-4"><span className="text-[14px]">{value.title}</span></td>
                        <td className="min-w-[150px] px-4"><span className="text-[14px]">{value.follower != null ? value.follower.name : 'Không tồn tại'}</span></td>
                        <td className="min-w-[200px] px-4"><span className="text-[14px]">{value.handover_recipients != null ? value.handover_recipients.name : 'Không tồn tại'}</span></td>
                        <td className="min-w-[150px] px-4"><span className="text-[14px]">{getTimestamp('text',value.created_at)}</span></td>
                        <td className="min-w-[150px] px-4"><span className="text-[14px]">
                        {value.status === 2 ? 'Đã từ chối' : ''}
                        {value.status === 1 ? 'Đã chấp thuận' : ''}
                          </span></td>
                      </tr>
                        ))
                        :
                        <div className="flex items-center justify-between mb-3 py-4 border-b border-gray-200 pb-4 ">
                          <h1 className="text-[14px] px-4 text-center">Bạn không có lịch sử nghỉ phép</h1>
                        </div>
                      }
                   </tbody>
                 </table>
               </div>
             </div>
           </form> 
        </div>
      </div>
    </div>
  );
}

