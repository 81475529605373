import React, { useState, useEffect } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomePage from "./pages/HomePage";
import "./App.css";
import Login from "./pages/dang-nhap";
import Layout from "./component/layout";
import configApi from "./api/config";
import NghiPhep from "./pages/nghi-phep";
import { useDispatch, useSelector } from "react-redux";
import authApi from "./api/authApi";
import constants from "./constants";
import { login } from "./store/slices/authSlice";
import Account from "./pages/tai-khoan";
import HistoryCheckIn from "./pages/lich-su-cham-cong";
import HistoryTimeOff from "./pages/lich-su-nghi-phep";
import AccountInformation from "./pages/thong-tin-ca-nhan";
import ChangePassword from "./pages/doi-mat-khau";
import Company from "./pages/cong-ty";
import BangLuong from "./pages/bang-luong";

const App = ({data}) => {
  const dispatch = useDispatch();
  const [setting,setSetting] = useState();
  const userInfo = useSelector((state) => state.authReducer);
  useEffect(()=>{
    // thông tin
    configApi.getConfig()
      .then((res) => {
        if (res && res.result == true) {
          setSetting(res.data);
        }
        return [];
      });
    const token = localStorage.getItem(constants.access_token);
    if(token){
      authApi.user(token).then((response)=>{
        if (response.result == true && response.data) {
          dispatch(login(response.data));
        }
      })
    }

},[])
  return (
  
    <BrowserRouter>
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/nghi-phep" element={<NghiPhep/>}></Route>
        <Route path="/login" element={<Login setting={setting}/>}></Route>
        <Route path="/tai-khoan" element={<Account/>}></Route>
        <Route path="/lich-su-cham-cong" element={<HistoryCheckIn/>}></Route>
        <Route path="/lich-su-nghi-phep" element={<HistoryTimeOff/>}></Route>
        <Route path="/thong-tin-ca-nhan" element={<AccountInformation/>}></Route>
        <Route path="/doi-mat-khau" element={<ChangePassword/>}></Route>
        <Route path="/cong-ty" element={<Company/>}></Route>
        <Route path="/bang-luong" element={<BangLuong/>}></Route>
      </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;