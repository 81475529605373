import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import authApi from "../../api/authApi";
import constants from "../../constants";
import { successToast } from "../../utils/toast";
const AccountInformation = () => {
  const user = useSelector((state) => state.authReducer);
  const userInfo = user.user;
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [image, setImage] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const updateUserInformation = async (e) => {
    const token = localStorage.getItem(constants.access_token)
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    if (!!selectedImage) {
      formData.append("avatar", selectedImage);
    }
    try {
      const response = await authApi.updateUserInfo(token, formData);
      if (response.message === "Cập nhật thành công") {
          successToast(response.message)
      } else
        throw new Error(
          "Cập nhập thông tin không thành công, vui lòng thử lại sau"
        );
    } catch (err) {
    }
  };

  const onChangeImageHandler = (e) => {
    const filesList = e.target.files;
    if (filesList) {
      setImage(URL.createObjectURL(filesList[0]));
      setSelectedImage(filesList[0]);
    }
  };
  useEffect(() => {
    if (userInfo) {
      setName(userInfo?.name)
      setEmail(userInfo?.email)
      setPhone(userInfo?.phone)
      setImage(
        userInfo?.avatar
          ? `${userInfo.avatar}`
          : '/avatar.jpg'
      );
    }
  }, [userInfo]);

  return (
    <>
      <div>
        <div className="sticky z-30 top-0 right-0 left-0 flex justify-center items-center pr-4 pl-4 lg:h-[60px] h-[50px] bg-primary-950 ">
          <div className="max-w-[875px] mx-auto relative w-full flex justify-center">
            <div className="text-black font-bold text-base">
              Thông tin cá nhân
            </div>
          </div>
        </div>
        <div className="max-w-[875px] bg-white mx-auto pb-[100px] ">
          <form
            className=" mx-auto max-w-[550px] px-4 lg:px-0 pt-5 grid gap-2"
            onSubmit={updateUserInformation}
          >
            <div>
              <label htmlFor="file" className="block cursor-pointer">
                <div className="h-[100px] w-[100px] rounded-[50%] mt-10 px-1 py-1 border-2 border-primary-500 overflow-hidden flex mx-auto">
                  <img
                    width={100}
                    height={100}
                    className="w-full h-full rounded-[50%] object-cover"
                    src={image}
                    alt="avatar"
                  />
                </div>
              </label>
              <input
                id="file"
                type="file"
                accept="image/*"
                onChange={onChangeImageHandler}
                className="hidden"
              />
            </div>

            <div>
              <label className="font-semibold mb-3 block" htmlFor="name">
                Họ và tên
              </label>
              <input
                type="text"
                value={name}
                onChange={(e)=>setName(e.target.value)}
                id="name"
                className="block border focus:outline-blue-400 border-mine-shaft-200 rounded-md px-4 py-2 w-full"
              ></input>
            </div>
            <div>
              <label className="font-semibold mb-3 block" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                id="email"
                className="block border focus:outline-blue-400 border-mine-shaft-200 rounded-md   px-4 py-2 w-full"
              ></input>
            </div>
            <div>
              <label className="font-semibold mb-3 block" htmlFor="phone">
                Số điện thoại
              </label>
              <input
                type="number"
                value={phone}
                onChange={(e)=>setPhone(e.target.value)}
                id="phone"
                className="block border focus:outline-blue-400  px-4 py-2 w-full"
              ></input>
            </div>
            {/* <div>
              <label className="font-semibold mb-3 block" htmlFor="address">
                Ngày sinh
              </label>
              <input
                type="date"
                defaultValue={defaultBirthDay ? defaultBirthDay : ""}
                id="address"
                className="block border focus:outline-blue-400 border-mine-shaft-200 rounded-md   px-4 py-2 w-full"
              ></input>
            </div> */}
            {/* <div>
              <label className="font-semibold mb-3 block" htmlFor="gender">
                Giới tính
              </label>
              <select
                type="number"
                // defaultValue={userInfo?.gender ? userInfo.gender : ""}
                id="gender"
                className="block border focus:outline-blue-400  px-4 py-2 w-full"
                onChange={(e) => setGender(e.target.value)}
              >
                <option value={""} selected={!gender}>
                  Khác
                </option>
                <option value={"Nam"} selected={gender === "Nam"}>
                  Nam
                </option>
                <option value={"Nữ"} selected={gender === "Nữ"}>
                  Nữ
                </option>
              </select>
            </div> */}
            {/* <div>
              <div className="font-semibold mb-3 block">Người quản lý</div>
              <div className="block border focus:outline-blue-400  px-4 py-2 w-full">
                <div></div>
              </div>
            </div> */}

            <div>
            <button className="w-full p-3 xs:p-4 overflow-hidden bg-[#17af26] border border-[#17af26] text-white leading-[0] rounded-[20px] flex items-center justify-center relative hover:bg-white transition-all hover:text-[#1C222B]">
                  <span className="text-sm xs:text-base font-medium leading-[100%] z-[2]">
                    Lưu
                  </span>
                </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccountInformation;
