import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";
import { useSelector } from "react-redux";
const Header = () => {
  const userInfo = useSelector((state) => state.authReducer);
  const user = userInfo.user;
  // console.log(userInfo);
  return (
    <>
    <ToastContainer/>
      <div className="relative w-full mx-auto lg:h-[100px] h-[80px] bg-[#3851C3] items-center rounded-b-[20px]">
        <div className="max-w-[875px] mx-auto h-full flex justify-between lg:items-center items-start relative">
          {userInfo.isLogin ? (
            <div className=" items-center hidden lg:flex">
              <div className="mt-3 pl-[30px] flex items-center gap-8">
                <a
                  href="/tai-khoan"
                  className="h-[52px] w-[52px] border-2 border-white rounded-full overflow-hidden"
                >
                  {user?.avatar != null ? (
                    <img
                      src={user.avatar}
                      className="w-full h-full"
                      height={50}
                      width={50}
                      alt='avatar'
                    />
                  ) : (
                    <img
                        src='/avatar.jpg'
                        className="w-full h-full"
                        height={50}
                        width={50}
                        alt="/avatar.jpg"
                      />
                    // <IoPersonCircleOutline size={50} className="text-white" />
                  )}
                </a>
                <div>
                  <p className="text-xs text-[#11C2F1]">Xin chào!</p>
                  <p className="font-blank text-base text-white">
                    {user?.name}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="ml-0 lg:ml-10 items-center hidden lg:flex">
              <a href="/login" className="font-bold text-white text-base">
                Đăng nhập / Đăng ký
              </a>
            </div>
          )}
          {userInfo.isLogin ? (
            <div className="mt-[14px] lg:hidden flex  items-center justify-between w-full ml-3 mr-5">
              <div className="flex items-center  gap-[10px]">
                <div className="h-[54px] w-[54px] border-2 border-white rounded-full overflow-hidden shrink-0">
                  {user?.avatar != null ? (
                    <img
                      src={user?.avatar}
                      className="w-full h-full"
                      height={50}
                      width={50}
                      alt="avatar"
                    />
                  ) : (
                    <img
                    src='/avatar.jpg'
                    className="w-full h-full"
                    height={50}
                    width={50}
                    alt="avatar"
                  />
                    // <IoPersonCircleOutline size={50} className="text-white" />
                  )}
                </div>
                <div>
                  <p className="text-xs text-[#11C2F1]">Xin chào!</p>
                  <p className="font-blank text-base text-white">
                    {user?.name}
                  </p>
                </div>
              </div>
              <div>
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-3 mt-[24px] mx-3 lg:hidden">
              {/* <IoPersonCircleOutline
                size={50}
                className="text-white shrink-0"
              /> */}
              <a href="/login" className="font-bold text-lg text-white">
                Đăng nhập / Đăng ký
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;

  
  