import React, { useState, useEffect } from "react";
import checkInOutApi from "../api/checkInOutApi";
import constants from "../constants";
import CheckInOutHome from "../component/checkInOutHome";
import CheckInOutPopup from "../component/popup-checkInOut";
import { warnToast } from "../utils/toast";
export default function HomePage(){
  const [checkIn,setCheckIn]=useState({
    data:[],
    week:[]
  })
  const [popupCheckIn,setPopupCheckIn] = useState(false)
  useEffect(() => {
    const token = localStorage.getItem(constants.access_token);
    if(token){
      checkInOutApi.getWeek(token).then((response) => {
        if (response.result == true && response.data) {
          setCheckIn({
            data:response.data,
            week:response.week
          });
        }
      });
    }else{
      window.location.href = "/login";
    }
  }, []);
  function handleCheckInOut(){
    if (typeof window !== 'undefined' && localStorage.getItem(constants.access_token)) {  
        setPopupCheckIn(true)    
    } else {
        warnToast('Bạn chưa đăng nhập',1500);
        return false;
    }
  }
  function closePopup(){
    setPopupCheckIn(false)
}
  return (
    <div className="App">
        {/* <div className="pt-1 sm:pt-4 lg:pt-0 w-full gap-2 grid lg:gap-[1px] pb-2 lg:pb-[1px] grid-cols-2 xs:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 sm:bg-white undefined">  */}
              <div className="pr-3 pl-3">
                <div className="grid grid-cols-3 lg:gap-3 gap-5 overflow-auto pb-2 place-items-center">
                {/* <a href={"/thong-tin-ca-nhan"} className="shrink-0 mt-5 w-[60px] sm:w-20">
                    <div className="w-[60px] sm:w-20 sm:h-20 h-[60px] flex items-center justify-center bg-[#E6F3F7]   rounded-[20px]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#0284c7" class="bi bi-person-fill" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                      </svg>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs sm:text-sm font-normal line-clamp-2 text-center">
                            Hồ sơ
                        </div>
                    </div>
                    </a> */}
                    {/* <a href={"/nghi-phep"} className="shrink-0 mt-5 w-[60px] sm:w-20">
                    <div className="w-[60px] sm:w-20 sm:h-20 h-[60px] flex items-center justify-center bg-[#E6F3F7]   rounded-[20px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#e11d48" class="bi bi-envelope-plus-fill" viewBox="0 0 16 16">
                        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 4.697v4.974A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-1.965.45l-.338-.207z"/>
                        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 0 0 1 0v-1h1a.5.5 0 0 0 0-1h-1v-1a.5.5 0 0 0-.5-.5"/>
                      </svg>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs sm:text-sm font-normal line-clamp-2 text-center">
                            Sự nghiệp
                        </div>
                    </div>
                    </a> */}
                  <a href={"/bang-luong"} className="shrink-0 mt-5 w-[60px] sm:w-20">
                    <div className="w-[60px] sm:w-20 sm:h-20 h-[60px] flex items-center justify-center bg-[#E6F3F7]   rounded-[20px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#facc15" class="bi bi-wallet" viewBox="0 0 16 16">
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1"/>
                    </svg>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs sm:text-sm font-normal line-clamp-2 text-center">
                            Bảng chấm công
                        </div>
                    </div>
                    </a>
                    {/* <a href={"/nghi-phep"} className="shrink-0 mt-5 w-[60px] sm:w-20">
                    <div className="w-[60px] sm:w-20 sm:h-20 h-[60px] flex items-center justify-center bg-[#E6F3F7]   rounded-[20px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#84cc16" class="bi bi-award-fill" viewBox="0 0 16 16">
                        <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864z"/>
                        <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                      </svg>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs sm:text-sm font-normal line-clamp-2 text-center">
                            Thành tựu
                        </div>
                    </div>
                    </a> */}
                    {/* <a href={"/nghi-phep"} className="shrink-0 mt-5 w-[60px] sm:w-20">
                    <div className="w-[60px] sm:w-20 sm:h-20 h-[60px] flex items-center justify-center bg-[#E6F3F7]   rounded-[20px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ea580c" class="bi bi-bag-check-fill" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0m-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                    </svg>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs sm:text-sm font-normal line-clamp-2 text-center">
                            Chính sách
                        </div>
                    </div>
                    </a> */}
                    <a onClick={handleCheckInOut} className="shrink-0 mt-5 w-[60px] sm:w-20">
                    <div className="w-[60px] sm:w-20 sm:h-20 h-[60px] flex items-center justify-center bg-[#E6F3F7]   rounded-[20px]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#2563eb" class="bi bi-alarm-fill" viewBox="0 0 16 16">
                      <path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5m2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.04 8.04 0 0 0 .86 5.387M11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.04 8.04 0 0 0-3.527-3.527"/>
                      </svg>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs sm:text-sm font-normal line-clamp-2 text-center">
                            Chấm công
                        </div>
                    </div>
                    </a>
                    <a href={"/nghi-phep"} className="shrink-0 mt-5 w-[60px] sm:w-20">
                    <div className="w-[60px] sm:w-20 sm:h-20 h-[60px] flex items-center justify-center bg-[#E6F3F7]   rounded-[20px]">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#14b8a6" class="bi bi-activity" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
                      </svg>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs sm:text-sm font-normal line-clamp-2 text-center">
                            Nghỉ phép
                        </div>
                    </div>
                    </a>
                    {/* <a href={"/nghi-phep"} className="shrink-0 mt-5 w-[60px] sm:w-20">
                    <div className="w-[60px] sm:w-20 sm:h-20 h-[60px] flex items-center justify-center bg-[#E6F3F7]   rounded-[20px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-gear-fill" viewBox="0 0 16 16">
                      <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                    </svg>
                    </div>
                    <div className="mt-3">
                        <div className="text-xs sm:text-sm font-normal line-clamp-2 text-center">
                            Ứng dụng 
                        </div>
                    </div>
                    </a> */}
                </div>
                {
                popupCheckIn == true ? <CheckInOutPopup closePopup={closePopup}/> :''
              }
                {/* <div className="bg-red-400 p-2 xs:p-5 rounded lg:rounded-none overflow-hidden undefined">
                    <a href={"/nghi-phep"}>
                      Nghỉ phép
                    </a>
                </div> */}
              </div>
        {/* </div> */}
        {checkIn.data && <CheckInOutHome data={checkIn}/>}
    </div>
  );
};