
import Footer from "./footer";
import Header from "./header";

export default function Layout({ children }) {
  return (
    <div id="main" className="overflow-hidden bg-gray-100 pb-20 xs:pb-0">

        <meta
          name="description"
          content="Generated by tung9122002@gmail.com"
        />
        <link rel="icon" href="/logo.png" />
      <div className="max-w-[875px]  mx-auto relative bg-white">
        <Header />
        {children}
        <Footer />
      </div>
    </div>
  );
}
