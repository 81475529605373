import { useEffect, useState } from "react";
import constants from "../../constants";
import { getTimestamp } from "../../utils/general";
import timeOffApi from "../../api/timeOffApi";
import checkInOutApi from "../../api/checkInOutApi";
import PopupDate from "../../component/popupDate";

export default function BangLuong({ data }) {
  const [listCheck,setListCheck] = useState({
    data:[],
    month:[],
  })
  const [userDate,setUserDate]=useState({
    date:null,
    total:null
  })
  const [popup,setPopup] = useState(false)
  let totalMonthlyHours = 0;
  useEffect(()=>{
    const token = localStorage.getItem(constants.access_token)
    if(token){
      month()
    }else{
      window.location.href = "/login";
    }
  },[])
  // Hiển thị bảng lương
  function month() {
    const token = localStorage.getItem(constants.access_token)
    checkInOutApi.getMonth(token).then((res)=>{
      if(res && res.result === true){
        setListCheck({
            data:res.data,
            month:res.month,
        })
      }
    })
  }
  function popupDate(date,total){
    setPopup(true)
    setUserDate({
      date:date,
      total:total
    })
  }
  function closePopup(){
    setPopup(false)
}
  return (
    <div id="main" className="overflow-hidden bg-white pb-20 xs:pb-0">
      <div className="sm:container">
        <div className="w-full max-w-4xl mx-auto mb-8">
          <div className="w-full bg-white px-4 mb-3">
          </div>

          <form id="" className="">
             <div className="w-full bg-white mb-3 overflow-hidden">
               <div className="flex items-center justify-between mb-3 py-4 border-b border-gray-200 pb-4 ">
                 <label className="text-[14px] px-4">Chấm công</label>
               </div>
               <div className="px-4 overflow-x-auto">
                 <table className="">
                   <tbody className="">
                     <tr className="h-16 border-b border-gray-200 last:border-none">
                       <td className=" min-w-[120px] px-4"><span className="font-medium text-[14px]">Nhân viên</span></td>
                        {
                          listCheck && 
                          listCheck.month.map((value,ind)=>(
                            <td key={ind} className=" min-w-[120px] px-4"><span className="font-medium text-[14px]">{value}</span></td>
                          ))
                        }            
                             <td className=" min-w-[120px] px-4"><span className="font-medium text-[14px]">Số giờ đã làm</span></td>
                     </tr>
                      {
                        Object.keys(listCheck.data).length > 0 ?
                      <tr className="h-16 border-b border-gray-200 last:border-none">
                        <td className="min-w-[50px] px-4"><span className="text-[14px]">{listCheck.data.name}</span></td>
                        {
                          listCheck && 
                          listCheck.month.map((value,ind)=>{
                            let totalHours = 0;
                            let checkDate = false;
                            if (listCheck.data.check_in_out) {
                              listCheck.data.check_in_out.forEach(check_in_out => {
                                if (value === check_in_out.date) {
                                  checkDate = true;
                                  totalHours += check_in_out.hours;
                                  totalMonthlyHours += check_in_out.hours;
                                }
                              });
                            }
                            return (
                              <td className="text-center" key={ind}>
                                {checkDate ? (
                                  <div className="d-flex webinar-price-box mt-20 mb-10">
                                    <p className="btn active btn-warning cursor-pointer" id="popup-confirm" onClick={()=>popupDate(value,totalHours)}>
                                      <span className="bg-[#28B8DA] rounded-md p-2 text-white">{totalHours}</span>
                                    </p>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </td>
                            );
                          })
                        }   
                        <td className="min-w-[50px] px-4"><span className="text-[14px]">Tổng số giờ đã làm {totalMonthlyHours}</span></td>
                      </tr>
                        :
                        <div className="flex items-center justify-between mb-3 py-4 border-b border-gray-200 pb-4 ">
                          <h1 className="text-[14px] px-4 text-center">Bạn không có lịch sử chấm công</h1>
                        </div>
                      }
                   </tbody>
                 </table>
               </div>
             </div>
           </form> 
           {
            popup == true ? 
              <PopupDate closePopup={closePopup} data={userDate}/>
              : ''
           }
        </div>
      </div>
    </div>
  );
}

