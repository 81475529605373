import axiosClient from "./axiosClient";

const companyApi = {
      // lấy ca làm việc của người dùng
      company(token) {
        const url = "/company";
        return axiosClient.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
      },
};

export default companyApi;
