import axiosClient from "./axiosClient";

const timeOffApi = {
  getList(token) {
    const url = "/nghi-phep";
    return axiosClient.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
  },
    //thông tin
  postTimeOff(params, headers) {
    const url = "/nghi-phep/store";
    return axiosClient.post(url, params, headers)
},
};

export default timeOffApi;
