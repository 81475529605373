import axiosClient from "./axiosClient";

const configApi = {
    //thông tin
  getConfig() {
    const url = "/config";
    return axiosClient.get(url);
  },
};

export default configApi;
