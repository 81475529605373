import { useEffect, useState } from "react";
import constants from "../../constants";
import checkInOutApi from "../../api/checkInOutApi";
import { getTimestamp } from "../../utils/general";
export default function PopupDate(props) {
    const data = props.data;   
    const [listCheckInDate,setListCheckInDate]= useState([]);
    const [breakTime,setBreakTime] = useState({
        start:0,
        end:0
    })
    var token = localStorage.getItem(constants.access_token);
    useEffect(() => {
        checkInOutApi.check_date(token,'?date='+data.date).then((res)=>{
            if(res.status === 200){
                setListCheckInDate(res.data)
                setBreakTime({
                  start: res.company.break_time_start,
                  end: res.company.break_time_end
                })
            }
        })
    }, [token]);
    console.log(listCheckInDate);
    return (
        <div before=""
        className="w-full h-full fixed top-0 left-0 before:content-[attr(before)] before:bg-black before:top-0 before:left-0 before:absolute before:w-full before:h-full before:opacity-70 z-[99999]">
        <div
            className="w-11/12 max-w-[560px] bg-white py-7 px-3 sm:p-4 md:p-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md">
            <div onClick={() => props.closePopup()}
                className="close absolute top-0 right-0 rounded-full bg-gray-400 overflow-hidden text-white p-1 translate-x-1/2 -translate-y-1/2 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-x"
                    viewBox="0 0 16 16">
                    <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div>
                <h4 className="border-b-2 pb-[20px] border-[#e5e5e5]">{data.date}</h4>
                <div className="p-8">
                    <ul className="mb-[20px]">
                        {
                            data.total > 0 ? 
                            <li className="justify-between relative block p-4 border-2 border-[#ddd] mb-2">Số giờ làm việc
                            <span className="bg-[#28B8DA] float-right rounded-md px-2">
                                {data.total}</span></li>
                                : ''
                        }
                       
                        <li className="justify-between relative block p-4 border-2 border-[#ddd] mb-2">Thời gian làm việc: 07:00 - 19:00</li>
                        {
                           Object.keys(breakTime).length > 0 ?
                           <li className="justify-between relative block p-4 border-2 border-[#ddd] mb-2">Nghỉ trưa: {breakTime.start} - {breakTime.end}</li>
                           : '' 
                        }
                            {Object.keys(listCheckInDate).length > 0 ? 
                                   <li className="max-h-[150px] overflow-y-auto justify-between block p-4 border-2 border-[#ddd] mb-2">
                                   <ul className="pl-0 mb-[20px]">
                                       <li className="max-h-[50px] z-2 text-white bg-[#337ab7] border-[#337ab7] rounded-t-lg p-2 border-2 border-[#ddd]">
                                           Nhật ký nhận phòng / trả phòng
                                       </li>
                                       {listCheckInDate.data.map(value => (
                                           <>
                                               <li className="justify-between relative block p-4 border-2 border-[#ddd]">
                                                   vào: {getTimestamp('number', value.check_in_time)}
                                               </li>
                                               {value.check_out_time && (
                                                   <li className="justify-between relative block p-4 border-2 border-[#ddd] mb-2">
                                                       ra: {getTimestamp('number', value.check_out_time)}
                                                   </li>
                                               )}
                                           </>
                                       ))}
                                   </ul>
                               </li>
                        : ''
                            }
                        
                    </ul>

                </div>
            </div>

        </div>
    </div>
    );
}