
import { useEffect, useState } from "react";
import authApi from "../../api/authApi";
import constants from "../../constants";
import { successToast, warnToast } from "../../utils/toast";
import { useDispatch, useSelector } from "react-redux";

export default function Login(props) {

  const [phone, setPhone] = useState("");
  const [password, setPasword] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const setting = props.setting;
  function submitLogin() {

    setIsLoading(true);
    const data = {
      phone:phone,
      password:password,
    }
    authApi.login(data).then((response) => {
      if (response.result === true) {
        localStorage.setItem(
          constants.access_token,
          response.data.access_token
        );
        successToast(response.message,1500);
        setTimeout(() => {
          window.location.href = "/";
      }, 1500);
      } else {
        warnToast('Tài khoản hoặc mật khẩu không chính xác!');
      }
    });
    setIsLoading(false);
  }

  return (
    <main>
      <section className="bg-white mx-atuo relative z-[1] min-h-screen pt-10 pb-16 sm:pt-24 sm:pb-40">
        <div className="w-full max-w-xl lg:max-w-[600px] m-auto px-4">
          <div className="lg:grid lg:grid-cols-1 items-center bg-white rounded-[10px] overflow-hidden shadow-xl">
            <div className="px-4 sm:px-10 xl:px-[60px] py-4 w-full">
              <div className="w-[50px] h-[50px] mx-auto">
                <img
                  className="w-full h-full"
                  src={setting?.logo_website}
                  width={0}
                  height={0}
                  sizes={"100vw"}
                />
              </div>
              <h3 className="color2 text-2xl font-semibold text-center mt-2 xl:mt-4">
                Đăng nhập
              </h3>

              <div className="mt-[26px]">
                {/* Tài khoản */}
                <div className=" py-[14px]  mb-3 border border-[#E0E0E0]">
                  <div className="px-6 gap-4 flex items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#BDBDBD"
                        className="bi bi-telephone"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                      </svg>
                    </div>
                    <input
                      onChange={(e) => setPhone(e.target.value)}
                      className="w-full outline-none "
                      type="text"
                      name=""
                      placeholder="Số điện thoại*"
                    />
                  </div>
                </div>

                {/* Mật khẩu */}
                <div className=" py-[14px]  border border-[#E0E0E0]">
                  <div className="px-6 gap-4 flex items-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <path
                          d="M10.1782 10.6655C7.22215 10.6655 4.26706 10.6655 1.31101 10.6655C1.10034 10.6655 0.889677 10.677 0.679967 10.6626C0.303638 10.6368 0.0182795 10.3332 0.00104307 9.95976C-0.0181085 9.53747 0.227989 9.20136 0.613894 9.13241C0.691458 9.11805 0.771894 9.11326 0.850416 9.11326C7.0517 9.11326 13.2539 9.11326 19.4552 9.1123C19.9043 9.1123 20.1983 9.31244 20.3046 9.6926C20.4358 10.1599 20.1131 10.631 19.6285 10.6636C19.4227 10.6779 19.2158 10.6655 19.009 10.6655C16.0654 10.6655 13.1208 10.6655 10.1772 10.6655H10.1782Z"
                          fill="#BDBDBD"
                        />
                        <path
                          d="M17.7212 4.59363C17.7212 4.23262 17.7308 3.87066 17.7193 3.50965C17.7001 2.9102 17.1333 2.33087 16.5319 2.31746C15.8137 2.30118 15.0946 2.31363 14.3754 2.31459C14.1044 2.31459 13.8794 2.22075 13.7137 2.0005C13.3652 1.53703 13.6189 0.877262 14.1887 0.769056C14.2778 0.751819 14.3697 0.744159 14.4607 0.744159C15.0927 0.742244 15.7237 0.746074 16.3557 0.742244C18.1808 0.73171 19.2945 2.20734 19.2907 3.69255C19.2888 4.36381 19.2936 5.03507 19.2878 5.70538C19.284 6.19087 18.9019 6.53177 18.4222 6.48868C18.0276 6.45325 17.7317 6.11905 17.725 5.68814C17.7193 5.3233 17.7241 4.95751 17.7241 4.59267C17.7241 4.59267 17.7231 4.59267 17.7222 4.59267L17.7212 4.59363Z"
                          fill="#BDBDBD"
                        />
                        <path
                          d="M19.2711 15.1951C19.2711 15.6193 19.3123 16.0492 19.2635 16.4677C19.1007 17.8753 18.0588 18.9143 16.6588 19.0196C15.888 19.0771 15.1114 19.0426 14.3367 19.0474C14.2745 19.0474 14.2103 19.0321 14.149 19.0158C13.7602 18.9143 13.5122 18.5677 13.5582 18.1932C13.6099 17.7681 13.9173 17.4837 14.3434 17.4808C14.9984 17.476 15.6543 17.4894 16.3093 17.476C16.519 17.4722 16.7441 17.4444 16.9346 17.364C17.4278 17.1552 17.7016 16.7617 17.7151 16.2254C17.7332 15.5273 17.7198 14.8273 17.7227 14.1293C17.7237 13.7922 17.8587 13.525 18.1709 13.3766C18.6985 13.1257 19.2778 13.4954 19.2874 14.0871C19.2931 14.4568 19.2884 14.8254 19.2884 15.1951C19.2826 15.1951 19.2759 15.1951 19.2702 15.1951H19.2711Z"
                          fill="#BDBDBD"
                        />
                        <path
                          d="M4.90509 17.4785C5.2661 17.4785 5.62806 17.4727 5.98907 17.4795C6.5301 17.49 6.89111 18.0004 6.72545 18.5098C6.62682 18.8124 6.31943 19.0441 5.96609 19.0451C5.17608 19.047 4.38512 19.0432 3.59608 19.0231C3.16996 19.0125 2.78405 18.8392 2.42687 18.6199C1.54111 18.076 1.08148 17.2669 1.03743 16.2384C1.00678 15.525 1.02785 14.8097 1.03072 14.0944C1.03264 13.6961 1.28544 13.3877 1.66368 13.3092C2.0065 13.2383 2.37708 13.4193 2.51401 13.7506C2.56955 13.8857 2.59158 14.0427 2.59349 14.1902C2.60115 14.8289 2.60211 15.4685 2.59349 16.1082C2.58871 16.5554 2.74862 16.9202 3.09718 17.1998C3.30785 17.3684 3.54916 17.467 3.8192 17.4727C4.1802 17.4804 4.54217 17.4747 4.90317 17.4747V17.4766L4.90509 17.4785Z"
                          fill="#BDBDBD"
                        />
                        <path
                          d="M1.04754 4.57925C1.04754 4.1627 1.00732 3.74137 1.0552 3.32961C1.17011 2.35001 1.66997 1.60597 2.52796 1.1042C2.96653 0.848522 3.44053 0.747019 3.94039 0.745104C4.61548 0.742231 5.29153 0.740316 5.96663 0.745104C6.27688 0.747019 6.51915 0.887783 6.6647 1.16452C6.80642 1.4336 6.80834 1.70747 6.63406 1.96602C6.47031 2.20924 6.22996 2.31458 5.94077 2.31458C5.26568 2.31458 4.58963 2.3117 3.91454 2.31458C3.33711 2.31649 2.93972 2.60472 2.69075 3.11032C2.59595 3.3028 2.58542 3.51442 2.58733 3.72701C2.59212 4.38199 2.59882 5.03793 2.59403 5.69292C2.59116 6.09606 2.35272 6.3862 1.97448 6.47909C1.65656 6.55665 1.30513 6.39769 1.13085 6.09989C1.04563 5.95338 1.03318 5.79155 1.03222 5.6278C1.03222 5.27828 1.03222 4.92877 1.03222 4.57925C1.03701 4.57925 1.0418 4.57925 1.04563 4.57925H1.04754Z"
                          fill="#BDBDBD"
                        />
                      </svg>
                    </div>
                    <input
                      onChange={(e) => setPasword(e.target.value)}
                      className="w-full outline-none "
                      type="password"
                      name=""
                      placeholder="Mật khẩu"
                    />
                  </div>
                </div>

                {/* Ghi nhớ mật khẩu và quên mật khẩu */}
              
                <div className="mt-4 flex justify-between items-center zoom-80 xs:zoom-100">
                  <div className="flex items-center gap-2">
                    <input
                      className=""
                      type="radio"
                      id="male"
                    />
                    <label
                      className="text-texth color-text cursor-pointer"
                      for="male"
                    >
                      Ghi nhớ mật khẩu
                    </label>
                  </div>
                  {/* <a href={"/quen-mat-khau"}>
                  <p className="text-[#2D9CDB] cursor-pointer">Quên mật khẩu?</p>
                  </a> */}
                </div>

                <div className="mt-5 text-center">
                  <button 
                    onClick={()=>submitLogin()}
                    className="border-[#ff5b2e] bg-[#ff5b2e] font-bold text-white w-2/3 h-[40px] rounded-[5px] "
                  >
                    Đăng nhập
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
