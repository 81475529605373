import React, { useRef } from "react";
import { successToast, warnToast } from "../../utils/toast";
import constants from "../../constants";
import authApi from "../../api/authApi";
const ChangePassword = () => {
  const token = localStorage.getItem(constants.access_token);
  const changePasswordHandler = async (e) => {
    e.preventDefault();
    const old_password = e.target.old_password.value;
    const new_password = e.target.password.value;
    const repeat_password = e.target.confirm_password.value;
    if (new_password !== repeat_password) {
        warnToast('Mật khẩu xác nhận không trùng khớp !')
    }
    try {
      const body = {
        old_password: old_password,
        password: new_password,
        password_confirmation:repeat_password
      };
      authApi.changePassword(body,token).then((res)=>{
        console.log(res);
        if (res.result === true) {
            successToast('Đổi mật khẩu thành công!',1500)
          } else {
            warnToast(res.message,1500);
          }
      });
    } catch (err) {
    }
  };
  return (
    <>
      <div>
        <form
          className="max-w-[550px] px-3 py-2 mx-auto "
          onSubmit={changePasswordHandler}
        >
          <div className="">
            <label className="font-semibold mb-3 block" htmlFor="name">
              Mật khẩu cũ
            </label>
            <div className="relative">
              <input
                name="old_password"
                type="password"
                // defaultValue={userInfo.name}
                id="name"
                className="block border focus:outline-blue-400 border-mine-shaft-200 rounded-md pr-4 pl-10 py-2 w-full relative"
              ></input>

            </div>
          </div>
          <div className="mt-3">
            <label className="font-semibold mb-3 block" htmlFor="name">
              Mật khẩu mới
            </label>
            <div className="relative">
              <input
                name="password"
                type="password"
                // defaultValue={userInfo.name}
                id="name"
                className="block border focus:outline-blue-400 border-mine-shaft-200 rounded-md pr-4 pl-10 py-2 w-full relative"
              ></input>
            </div>
          </div>
          <div className="mt-3">
            <label className="font-semibold mb-3 block" htmlFor="name">
              Nhập lại mật khẩu mới
            </label>
            <div className="relative">
              <input
                name="confirm_password"
                type="password"
                // defaultValue={userInfo.name}
                id="name"
                className="block border focus:outline-blue-400 border-mine-shaft-200 rounded-md pr-4 pl-10 py-2 w-full relative"
              ></input>
            </div>
          </div>
          <div className="mt-3">
          <button className="w-full p-3 xs:p-4 overflow-hidden bg-[#17af26] border border-[#17af26] text-white leading-[0] rounded-[20px] flex items-center justify-center relative hover:bg-white transition-all hover:text-[#1C222B]">
                  <span className="text-sm xs:text-base font-medium leading-[100%] z-[2]">
                    Lưu
                  </span>
                </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
